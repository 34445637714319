import { ReactNode } from "react";
import SelectT from "react-tailwindcss-select";
import { SelectValue } from "react-tailwindcss-select/dist/components/type";
import { RoleGroup } from "../../typings/api/role-group";
import { Label } from "../label";

export type Option = {
    value: string | number;
    label: string;
};

export const Select = ({
    value,
    onChange,
    options,
    label,
    noMarginBottom,
    placeholder,
    isClearable = true,
    disabled = false,
    isMultiple = false,
    isSearchable = true,
}: {
    value: string | string[] | number | number[] | null | RoleGroup;
    onChange: (value?: string | string[] | number | null) => any;
    options: Option[];
    label?: ReactNode;
    noMarginBottom?: boolean;
    placeholder?: string;
    isClearable?: boolean;
    disabled?: boolean;
    isMultiple?: boolean;
    isSearchable?: boolean;
}) => {
    return (
        <>
            {label && (
                <div className={noMarginBottom ? "mb-0" : "mb-1"}>
                    <Label text={label} />
                </div>
            )}
            <SelectT
                isClearable={isClearable}
                placeholder={placeholder || ""}
                isDisabled={disabled}
                isMultiple={isMultiple}
                isSearchable={isSearchable}
                classNames={{
                    menuButton: () =>
                        "flex bg-white dark:bg-gray-900 dark:text-gray-400 border " +
                        "border-gray-300 dark:border-gray-900 rounded-md shadow-sm " +
                        "text-left cursor-default focus:outline-none focus:ring-1 " +
                        "focus:ring-indigo-500 focus:border-indigo-500 focus:dark:ring-indigo-900 focus:dark:border-indigo-900  sm:text-sm " +
                        "overflow-hidden placeholder-gray-400 dark:placeholder-gray-400 ",
                    menu:
                        "absolute z-10 w-full dark:bg-gray-900 bg-white shadow-lg " +
                        " border border-gray-300 dark:border-gray-900 rounded py-1 " +
                        "mt-1.5 text-sm text-gray-700 z-50",
                    searchBox:
                        "w-full dark:bg-gray-800 py-2 pl-8 text-sm text-gray-500 dark:text-gray-300 " +
                        "bg-gray-100 border border-gray-300 dark:border-gray-600 rounded " +
                        "focus:border-gray-300 focus:ring-0 focus:outline-none ",
                    listItem: () =>
                        "dark:text-gray-400 block transition duration-200 px-2 " +
                        "py-2 cursor-pointer select-none truncate rounded text-gray-500 " +
                        "hover:bg-blue-100 hover:dark:bg-indigo-700 hover:text-gray-500 hover:dark:text-green-300 text-left ",
                }}
                primaryColor="white"
                value={
                    isMultiple
                        ? (options.filter((o) => ((value || []) as any[]).includes(o.value)) as SelectValue)
                        : (options.find((o) => o.value === value) as SelectValue)
                }
                onChange={(v) => {
                    const value = v ? (Array.isArray(v) ? v.map((s) => s.value || null) : v.value || null) : null;
                    onChange(value as any);
                }}
                options={options as any[]}
            />
        </>
    );
};
