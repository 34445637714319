import APIService from "./api.service";

export type MondaySyncData = {
    id: number;
    clearErcId: string;
    ercData: string;
    ercLastUpdated: Date;
    fieldId: number;
    mondayData: string;
    mondayId: string;
    mondayLastUpdated: Date;
    processed: number;
};

class MondaySyncDataService extends APIService<MondaySyncData> {
    constructor() {
        super("monday-sync-data");
    }
}

export default new MondaySyncDataService();
